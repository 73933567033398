.rdg {
  background: transparent;
  border: none;
}

/* ROW */
.rdg-row {
  background: transparent;
}

.rdg-row:hover,
.rdg-row:focus {
  background: rgba(255, 163, 56, 0.3);
}

.rdg-row--active-editor {
  contain: none !important;
}

/* ROW HEADER */
.rdg-header-row {
  background: white;
  border-bottom: 1px solid grey;
  border-radius: 8px 8px 0 0;
}

/* CELL */
.rdg-cell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 4px 1px;
  background: white;
  border: 1px solid white;
  border-radius: 4px;
  box-shadow: none !important;
  overflow: auto;
  line-height: 1;
}

.rdg-cell:nth-child(-n + 3) {
  margin-left: 0px;
}

.rdg-cell:last-child {
  margin-right: 0;
}

.rdg-cell:hover,
.rdg-cell:focus {
  border: 1px solid #ffa338;
}

.rdg-cell--custom-disabled {
  border: none;
  pointer-events: none;
}

.rdg-cell--custom-error {
  border-color: #b00020 !important;
}

.rdg-cell--custom-autogenerated {
  border-color: rgba(0, 0, 0, 0.12);
}

.rdg-cell--custom-changed {
  border-color: rgba(0, 0, 0, 0.5);
}

.rdg-cell:not(.rdg-cell-frozen):not([role='columnheader']) {
  position: relative;
}

.rdg-cell--asana-tasks {
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
  line-height: 20px;
  text-align: center;
  padding: 2px;
  border-radius: 0 50%;
  background: #e00020;
  color: white;
  font-weight: bold;
  cursor: pointer;
  border: none;
}

.rdg-cell--active-editor {
  contain: none !important;
  overflow: visible !important;
  z-index: 100;
}

.rdg-cell--textarea-wrapper {
  width: calc(200% + 4px);
  height: 200%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.rdg-cell--textarea {
  width: 100%;
  height: 100%;
  outline: none;
  border-radius: 4px;
}

/* CELL HEADER */
.rdg-cell[role='columnheader'] {
  border-radius: 0;
  border: none;
  margin: 0;
  text-transform: uppercase;
}

.rdg-cell[role='columnheader']:hover {
  background: rgba(255, 163, 56, 0.3);
}

.rdg-cell[role='columnheader']:first-child {
  margin-left: 0;
}

.rdg-cell[role='columnheader']:last-child {
  margin-right: 0;
}

/* SAVE MODAL */
ins {
  background: rgba(67, 160, 71, 0.5);
}

del {
  background: rgba(176, 0, 32, 0.5);
}
.currently-selected,
.currently-selected.search-area-selected {
  border: 3px solid #ffa338;
}

.search-area-selected {
  border: 1px solid #ffa338;
}

.MuiIconButton-colorSecondary {
  border: 1px solid #cd3d32;
}

.error-row > .rdg-cell:first-of-type::before {
  content: '';
  width: 100%;
  position: absolute;
  border: 1px solid #b00020;
  background-color: #ff00001e;
  left: 0;
  height: 100%;
}

.draft-row > .rdg-cell {
  background-color: #F7F7F7;
  color: #777777;
}
